<script setup lang="ts">
import { type CmsElementProductSlider } from "@shopware/composables";
import { useCmsElementConfig } from "#imports";
import { computed, ref } from "vue";
import ProductSlideCard from "~/components/cms/element/ProductSlideCard.vue";

const props = defineProps<{
  content: CmsElementProductSlider;
}>();
const { getConfigValue } = useCmsElementConfig(props.content);
const title = computed(() => getConfigValue("title"));

import { Carousel, Slide, Navigation } from "vue3-carousel";
import SharedHeadline from "~/components/shared/SharedHeadline.vue";
import SharedTopline from "~/components/shared/SharedTopline.vue";

const settings = {
  itemsToShow: 5,
  itemsToScroll: 5,
  wrapAround: false,
  snapAlign: "start" as "start",
  autoplay: 5000,
  transition: 600,
  pauseAutoplayOnHover: true,
  breakpoints: {
    // 200px and up
    200: {
      itemsToShow: 1,
      itemsToScroll: 1,
      snapAlign: "center" as "center",
    },
    400: {
      itemsToShow: 1,
      itemsToScroll: 1,
      snapAlign: "start",
    },
    600: {
      itemsToShow: 2,
      itemsToScroll: 2,
      snapAlign: "start",
    },
    900: {
      itemsToShow: 3,
      itemsToScroll: 3,
      snapAlign: "start",
    },
    1200: {
      itemsToShow: 4,
      itemsToScroll: 4,
      snapAlign: "start",
    },
  },
};

const productSlider = ref(null);
const currentSlide = ref(0);
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);
</script>
<template>
  <section>
    <div class="mb-10">
      <div class="container mx-auto">
        <SharedTopline :headline="title" headlineType="h3"></SharedTopline>
      </div>
    </div>
    <div v-if="props.content?.data?.products">
      <div class="container max-w-[90rem] laptop:px-10">
        <div id="ProductsCarousel" class="-mx-2.5">
          <Carousel
            v-bind="settings"
            ref="productSlider"
            v-model="currentSlide"
          >
            <Slide
              v-for="product of props.content?.data?.products"
              :key="product.id"
            >
              <ProductSlideCard :product="product"></ProductSlideCard>
            </Slide>
            <template #addons>
              <div
                class="carousel__navigation-button pointer-events-none z-0 absolute bottom-0 md:bottom-auto top-1/4 md:top-2/4 max-w-[90rem] flex justify-between desktop:-left-[60px] desktop:-right-[60px] -left-5 -right-5 mx-auto px-3 laptop:px-10 [&>button]:static"
              >
                <Navigation>
                  <template #next>
                    <img
                      src="assets/icons/slide-right.svg"
                      alt="Next"
                      class="w-6 h-6"
                    />
                  </template>
                  <template #prev>
                    <img
                      src="assets/icons/slide-left.svg"
                      alt="Previous"
                      class="w-6 h-6"
                    />
                  </template>
                </Navigation>
              </div>
            </template>
          </Carousel>
        </div>
        <div class="text-center mt-[3.75rem]">
          <NuxtLink
            :to="formatLink(`/shop/`)"
            class="btn inline-flex relative hover:pr-16 group overflow-hidden"
          >
            {{ $t("productSlider.showAll") }}
            <span
              class="ml-3 absolute right-6 opacity-0 group-hover:opacity-100 transition-all duration-300"
            >
              <img src="assets/icons/btn-arrow.svg" alt="arrow-right" />
            </span>
          </NuxtLink>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.carousel__navigation-button :deep(.carousel__prev),
.carousel__navigation-button :deep(.carousel__next) {
  @apply bg-skin-icon text-skin-white w-12 h-12 rounded-full flex items-center justify-center;
  position: relative;
  pointer-events: all;
}
</style>
